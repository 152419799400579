import { faSlack } from '@fortawesome/free-brands-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faSpinnerThird } from '@fortawesome/pro-duotone-svg-icons';
import {
  faBullseyeArrow,
  faThumbsUp,
  faSign,
  faBolt,
  faHouseHeart,
  faMessageDollar,
  faFileChartColumn,
  faMapLocation,
  faMessagesDollar,
  faHouse as falHouse,
  faEnvelope as falEnvelope,
  faHighlighterLine,
  faMemo as falMemo,
  faPhone as falPhone,
} from '@fortawesome/pro-light-svg-icons';

import {
  faExclamationTriangle,
  faCheck,
  faChevronRight,
  faChevronUp,
  faChevronDown,
  faPhone,
  faAsterisk,
  faEnvelope as farEnvelope,
  faCog,
  faTrees,
  faDog,
  faHatChef,
  faRoad,
  faLightCeiling,
  faTimes,
  faHeart,
  faPaperPlaneTop,
  faClock,
  faImages,
  faPlus,
  faExternalLink,
  faPaperPlane,
  faArrowsRotate,
  faStar,
  faGrid2 as farGrid2,
  faCaretRight as farCaretRight,
  faHome,
  faPenToSquare,
  faImage,
  faAngleLeft,
  faAngleRight,
  faCircle,
  faCircleHalfStroke,
  faMinus,
  faXmark,
  faLocationCrosshairs,
  faHandPointer,
  faCopy as farCopy,
  faSignHanging,
  faExpand,
  faSackDollar,
  faPartyHorn,
  faMap,
  faList as farList,
  faEnvelopeCircleCheck,
} from '@fortawesome/pro-regular-svg-icons';
import {
  faPhone as farPhone,
  faExclamationTriangle as farExclamationTriangle,
  faEnvelope,
  faCaretLeft,
  faCaretRight,
  faQuestionCircle,
  faHomeAlt,
  faAddressCard,
  faHouseUser,
  faChevronDoubleLeft,
  faChevronDoubleRight,
  faChevronRight as fasChevronRight,
  faChevronLeft,
  faFilter,
  faTrash,
  faGripLines,
  faSearch,
  faMagic,
  faHomeHeart,
  faBed,
  faBath,
  faRuler,
  faCity,
  faBuilding,
  faTreeAlt,
  faLockKeyhole,
  faSign as fasSign,
  faDollarSign,
  faCheck as fasCheck,
  faQuestion,
  faDash,
  faGrid2,
  faList,
  faPaperclip,
  faCamera,
  faTimes as fasTimes,
  faPlus as fasPlus,
  faMinus as fasMInus,
  faHouse,
  faCalendarDays,
  faLocationDot,
  faPencil,
  faCopy,
  faCirclePlus,
  faCircleCheck,
  faCircleXmark,
  faCircleQuestion,
  faCaretUp,
  faCaretDown,
  faFloppyDisk,
  faEye,
  faEyeSlash,
  faMemo,
  faCircleEnvelope,
  faCirclePhone,
  faClock as fasClock,
  faSlidersSimple,
  faSliders,
  faCircle as fasCircle,
  faChevronDown as fasChevronDown,
  faImage as fasImage,
  faThumbTack,
  faArrowRight,
  faHyphen,
  faArrowsSpin,
  faSpinner,
  faSquareCaretDown,
  faSquareCaretUp,
  faFlagSwallowtail,
  faHighlighter,
  faCircleInfo,
  faChevronsUp,
  faChevronsDown,
  faBoltLightning,
  faMoneyCheckDollar,
  faGripDotsVertical,
  faSquareDashed,
  faLock,
  faHandPointer as fasHandPointer,
  faLocationCrosshairs as fasLocationCrosshairs,
  faBookOpenCover,
  faStar as fasStar,
  faHouseBuilding,
  faGem,
  faEmptySet,
  faSnooze,
  faFire,
  faLightbulb,
  faTreeCity,
  faMap as fasMap,
} from '@fortawesome/pro-solid-svg-icons';

export const importFontAwesomeIcons = () => {
  library.add(
    faExclamationTriangle,
    farExclamationTriangle,
    faCheck,
    faChevronRight,
    faChevronUp,
    faChevronDown,
    faPhone,
    farPhone,
    faEnvelope,
    farEnvelope,
    faAsterisk,
    faSpinnerThird,
    faCog,
    faTrees,
    faDog,
    faHatChef,
    faRoad,
    faLightCeiling,
    faCaretLeft,
    faCaretRight,
    faQuestionCircle,
    faHomeAlt,
    faAddressCard,
    faHouseUser,
    faSign,
    faChevronDoubleLeft,
    faChevronDoubleRight,
    faChevronLeft,
    fasChevronRight,
    faFilter,
    faTimes,
    faHeart,
    faPaperPlaneTop,
    faClock,
    faImages,
    faSearch,
    faTrash,
    faGripLines,
    faPlus,
    faExternalLink,
    faMagic,
    faHomeHeart,
    faBed,
    faBath,
    faRuler,
    faCity,
    faBuilding,
    faTreeAlt,
    faThumbsUp,
    faBullseyeArrow,
    faBolt,
    faPaperPlane,
    faLockKeyhole,
    faArrowsRotate,
    faHouseHeart,
    faMessageDollar,
    faFileChartColumn,
    faMapLocation,
    fasSign,
    faDollarSign,
    fasCheck,
    faQuestion,
    faDash,
    faStar,
    faGrid2,
    faList,
    faPaperclip,
    faMessagesDollar,
    faCamera,
    farGrid2,
    fasPlus,
    fasMInus,
    fasTimes,
    faHouse,
    fasTimes,
    faCalendarDays,
    faLocationDot,
    faCopy,
    farCopy,
    faPencil,
    farCaretRight,
    faHome,
    faPenToSquare,
    faImage,
    faCirclePlus,
    faAngleLeft,
    faAngleRight,
    faCircleCheck,
    faCircleXmark,
    faCircleQuestion,
    faCaretUp,
    faCaretDown,
    faSlack,
    faFloppyDisk,
    faEye,
    faEyeSlash,
    faMemo,
    faCircleEnvelope,
    faCirclePhone,
    faSackDollar,
    faSlidersSimple,
    fasClock,
    faCircle,
    faCircleHalfStroke,
    fasCircle,
    fasChevronDown,
    fasImage,
    falHouse,
    falEnvelope,
    faThumbTack,
    faArrowRight,
    faHyphen,
    falMemo,
    faHighlighterLine,
    faArrowsSpin,
    faMinus,
    faXmark,
    faLocationCrosshairs,
    faHandPointer,
    faSpinner,
    faSquareCaretDown,
    faSquareCaretUp,
    faSignHanging,
    faFlagSwallowtail,
    faExpand,
    faHighlighter,
    faCircleInfo,
    falPhone,
    faChevronsUp,
    faChevronsDown,
    faBoltLightning,
    faMoneyCheckDollar,
    faGripDotsVertical,
    faSquareDashed,
    faLock,
    fasHandPointer,
    fasLocationCrosshairs,
    faBookOpenCover,
    fasStar,
    faHouseBuilding,
    faPartyHorn,
    faMap,
    farList,
    faGem,
    faEmptySet,
    faEnvelopeCircleCheck,
    faSnooze,
    faFire,
    faSliders,
    faLightbulb,
    faTreeCity,
    fasMap,
  );
};
